import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (reportTitle, accommodation, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const pdfContent = [
        {
          text: reportTitle,
          style: 'krishi',
          alignment: 'center'
        }
      ]
        const allRows = [
          [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? accommodation.fiscal_year_bn : accommodation.fiscal_year, style: 'td', alignment: 'center', bold: true },
            { text: vm.$t('org_pro.organization'), style: 'th', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? accommodation.org_bn : accommodation.org, style: 'td', alignment: 'center', bold: true }
          ],
          [
            { text: vm.$t('org_pro.office_type'), style: 'th', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? accommodation.office_type_bn : accommodation.office_type, style: 'td', alignment: 'center', bold: true },
            { text: vm.$t('org_pro.office_namel'), style: 'th', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? accommodation.office_name_bn : accommodation.office_nam, style: 'td', alignment: 'center', bold: true }
          ],
          [
            { text: vm.$t('elearning_accommodation.food_package'), style: 'th', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? accommodation.foor_package_bn : accommodation.foor_package, style: 'td', alignment: 'center', bold: true },
            { text: vm.$t('elearning_accommodation.guest_name_en'), style: 'th', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? accommodation.guest_name_bn : accommodation.guest_name, style: 'td', alignment: 'center', bold: true }
          ],
          [
            { text: vm.$t('elearning_accommodation.org_name'), style: 'th', alignment: 'center', bold: true },
            { text: accommodation.org_name, style: 'td', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center', bold: true },
            { text: accommodation.mobile, style: 'td', alignment: 'center', bold: true }
          ],
          [
            { text: vm.$t('externalUserIrrigation.nid'), style: 'th', alignment: 'center', bold: true },
            { text: accommodation.nid, style: 'td', alignment: 'center', bold: true },
            { text: vm.$t('elearning_accommodation.passport'), style: 'th', alignment: 'center', bold: true },
            { text: accommodation.passport, style: 'td', alignment: 'center', bold: true }
          ],
          [
            { text: vm.$t('elearning_config.address'), style: 'th', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? accommodation.address_bn : accommodation.address, style: 'td', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.remarks'), style: 'th', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? accommodation.remarks_bn : accommodation.remarks, style: 'td', alignment: 'center', bold: true }
          ],
          [
            { text: vm.$t('elearning_accommodation.bill_details'), colSpan: '4', style: 'th', alignment: 'center', bold: true }
          ],
          [
            { text: vm.$t('elearning_config.room_type'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.floor_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.room_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.room_rent'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        accommodation.accommodation_room_details.forEach((info, index) => {
          const roomType = vm.$store.state.TrainingElearning.commonObj.roomTypeList.find(doc => doc.value === parseInt(info.room_type_id))
          const floor = info.floor_no
          const room = vm.$store.state.TrainingElearning.commonObj.roomList.find(doc => doc.value === parseInt(info.room_no))
          allRows.push([
            { text: (i18n.locale === 'bn') ? roomType.text_bn : roomType.text_en, style: 'td', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? floor.text_bn : floor.text_en, style: 'td', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? room.text_bn : room.text_en, style: 'td', alignment: 'center', bold: true },
            { text: vm.$n(info.room_rent), style: 'td', alignment: 'center', bold: true }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['25%', '25%', '25%', '25%'],
            body: allRows
          }
        })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 10,
            margin: [1, 1, 1, 1]
          },
          td: {
            fontSize: 10,
            margin: [1, 1, 1, 1]
          },
          krishi: {
            fontSize: 15,
            margin: [0, 1, 0, 25],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('bill')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
